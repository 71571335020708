import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPage = () => (
  <Layout pageInfo={{ pageName: "privacy" }}>
  <Seo title="Datenschutz" />

  <h1 className="heading-4">Datenschutzerklärung</h1>
  <br/>
  <h2 className="heading-5">Allgemeiner Hinweis und Pflichtinformationen</h2>

  <p className="content-text"><strong>Benennung der verantwortlichen Stelle</strong></p>
  <p className="content-text">Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
  <p className="content-text"><span id="s3-t-firma">TrackSecure GmbH</span><br/><span id="s3-t-ansprechpartner">Hardi Probst</span><br/><span id="s3-t-strasse">Geiselgasteigstr. 114a</span><br/><span id="s3-t-plz">81545</span> <span id="s3-t-ort">München</span></p>
  <p></p>
  <p className="content-text">Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>

  <p className="content-text"><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
  <p className="content-text">Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

  <p className="content-text"><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></p>
  <p className="content-text">Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>

  <p className="content-text"><strong>Recht auf Datenübertragbarkeit</strong></p>
  <p className="content-text">Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

  <p className="content-text"><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></p>
  <p className="content-text">Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>

  <p className="content-text"><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
  <p className="content-text">Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

  <p className="content-text"><strong>Server-Log-Dateien</strong></p>
  <p className="content-text">In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
  <ul className="content-text">
      <li>Besuchte Seite auf unserer Domain</li>
      <li>Datum und Uhrzeit der Serveranfrage</li>
      <li>Browsertyp und Browserversion</li>
      <li>Verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>IP-Adresse</li>
  </ul>
  <p className="content-text">Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>

  <p className="content-text"><strong>Datenübermittlung bei Vertragsschluss für Warenkauf und Warenversand</strong></p>
  <p className="content-text">Personenbezogene Daten werden nur an Dritte nur übermittelt, sofern eine Notwendigkeit im Rahmen der Vertragsabwicklung besteht. Dritte können beispielsweise Bezahldienstleister oder Logistikunternehmen sein. Eine weitergehende Übermittlung der Daten findet nicht statt bzw. nur dann, wenn Sie dieser ausdrücklich zugestimmt haben.</p>
  <p className="content-text">Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>

  <p className="content-text"><strong>Kontaktformular</strong></p>
  <p className="content-text">Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</p>
  <p className="content-text">Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
  <p className="content-text">Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</p>

  <p className="content-text"><strong>YouTube</strong></p>
  <p className="content-text">Für Integration und Darstellung von Videoinhalten nutzt unsere Website Plugins von YouTube. Anbieter des Videoportals ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.</p>
  <p className="content-text">Bei Aufruf einer Seite mit integriertem YouTube-Plugin wird eine Verbindung zu den Servern von YouTube hergestellt. YouTube erfährt hierdurch, welche unserer Seiten Sie aufgerufen haben.</p>
  <p className="content-text">YouTube kann Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen, sollten Sie in Ihrem YouTube Konto eingeloggt sein. Durch vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden.</p>
  <p className="content-text">Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>
  <p className="content-text">Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: <a href="https://www.google.de/intl/de/policies/privacy" target="_blank" rel="noopener noreferrer">https://www.google.de/intl/de/policies/privacy</a>.</p>

  <p className="content-text"><strong>Vimeo</strong></p>
  <p className="content-text">Für Integration und Darstellung von Videoinhalten nutzt unsere Website Plugins von Vimeo. Anbieter des Videoportals ist die Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.</p>
  <p className="content-text">Bei Aufruf einer Seite mit integriertem Vimeo-Plugin wird eine Verbindung zu den Servern von Vimeo hergestellt. Vimeo erfährt hierdurch, welche unserer Seiten Sie aufgerufen haben. Vimeo erfährt Ihre IP-Adresse, selbst wenn Sie nicht beim Videoportal
  	eingeloggt sind oder dort kein Konto besitzen. Es erfolgt eine Übermittlung der von Vimeo erfassten Informationen an Server des Videoportals in den USA.</p>
  <p className="content-text">Vimeo kann Ihr Surfverhalten direkt Ihrem persönlichen Profil zuordnen. Durch vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden.</p>
  <p className="content-text">Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter: <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a>.</p>

  <p className="content-text"><strong>Cookies</strong></p>
  <p className="content-text">Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. </p>
  <p className="content-text">Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf
  	unserer Website wiederzuerkennen.</p>
  <p className="content-text">Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies
  	kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.</p>
  <p className="content-text">Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser
  	Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser
  	Datenschutzerklärung separat behandelt.</p>

  <p className="content-text"><strong>Google Analytics</strong></p>
  <p className="content-text">Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
  <p className="content-text">Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen über Ihre Benutzung unserer Website
  	werden an einen Server von Google übermittelt und dort gespeichert. Server-Standort ist im Regelfall die USA.</p>
  <p className="content-text">Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir  ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und ggf. auch Werbung zu optimieren.</p>
  <p className="content-text">IP-Anonymisierung</p>
  <p className="content-text">Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
  	vor der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen Google die volle IP-Adresse an einen Server in den USA überträgt und dort kürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website
  	auszuwerten, um Reports über Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Es findet keine Zusammenführung der von Google Analytics übermittelten
  	IP-Adresse mit anderen Daten von Google statt.</p>
  <p className="content-text">Browser Plugin</p>
  <p className="content-text">Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt
  	anschließender Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das über folgenden Link erreichbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
  <p className="content-text">Widerspruch gegen die Datenerfassung</p>
  <p className="content-text">Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, klicken Sie bitte auf die Cookie-Einstellungen rechts unten und schalten den Bereich Analyse-Cookies aus. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen unserer Website verhindert.
  Wenn Sie die Cookies in diesem Browser löschen, dann müssen Sie den Link erneut klicken. Ferner gilt das Opt-Out nur innerhalb des von Ihnen verwendeten Browsers und nur innerhalb unserer Webdomain, auf der der Link geklickt wurde.</p>
  <p className="content-text">Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
  <p className="content-text">Auftragsverarbeitung</p>
  <p className="content-text">Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit Google einen Vertrag über die Auftragsverarbeitung abgeschlossen.</p>
  <p className="content-text">Demografische Merkmale bei Google Analytics</p>
  <p className="content-text">Unsere Website verwendet die Funktion “demografische Merkmale” von Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung
  	von Google sowie aus Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu einer bestimmten Person ist nicht möglich. Sie können diese Funktion jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem Google-Konto möglich oder
  	indem Sie die Erfassung Ihrer Daten durch Google Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung” erläutert, generell untersagen.</p>

  <p className="content-text"><strong>Facebook-, Custom Audiences und Facebook-Marketing-Dienste</strong></p>
  <p className="content-text">Innerhalb unserer Website wird aufgrund unseren berechtigten Interessen an Analyse, Optimierung und wirtschaftlichem Betrieb unserer Firma und zu diesen Zwecken das sog. "Facebook-Pixel" des sozialen Netzwerkes Facebook, welches von der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA, bzw. falls Sie in der EU ansässig sind, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"), eingesetzt.</p>
  <p className="content-text">Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>).</p>
  <p className="content-text">Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z. B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Websites bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. "Custom Audiences"). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, indem wir sehen, ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden (sog. "Conversion").</p>
  <p className="content-text">Das Facebook-Pixel wird beim Aufruf unserer Websites unmittelbar durch Facebook eingebunden und kann auf Ihrem Gerät ein sog. Cookie, d. h. eine kleine Datei abspeichern. Wenn Sie sich anschließend bei Facebook einloggen oder im eingeloggten Zustand Facebook besuchen, wird der Besuch unseres Onlineangebotes in Ihrem Profil vermerkt. Die über Sie erhobenen Daten sind für uns anonym, bieten uns also keine Rückschlüsse auf die Identität der Nutzer. Allerdings werden die Daten von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist und von Facebook sowie zu eigenen Marktforschungs- und Werbezwecken verwendet werden kann. Sofern wir Daten zu Abgleichzwecken an Facebook übermitteln sollten, werden diese lokal in dem Browser verschlüsselt und erst dann an Facebook über eine gesicherte https-Verbindung gesendet. Dies erfolgt alleine mit dem Zweck, einen Abgleich mit den gleichermaßen durch Facebook verschlüsselten Daten herzustellen.</p>
  <p className="content-text">Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen der Datenverwendungsrichtlinie von Facebook.
  Dementsprechend generelle Hinweise zur Darstellung von Facebook-Ads erhalten Sie in der Datenverwendungsrichtlinie von Facebook: <a target="_blank" rel="noopener noreferrer" href="www.facebook.com/policy.php">www.facebook.com/policy.php</a>.
  Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise erhalten Sie im Hilfebereich von Facebook:
    &nbsp;<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/business/help/651294705016616">https://www.facebook.com/business/help/651294705016616</a>.
  </p>
  <p className="content-text">Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von Facebook-Ads widersprechen.
  Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook angezeigt werden, können Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen nutzungsbasierter Werbung befolgen:
  &nbsp;<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/settings">www.facebook.com/settings</a>. Die Einstellungen erfolgen plattformunabhängig, d. h. sie werden für alle Geräte (Computer, mobile Geräte, ...) übernommen.</p>
  <p className="content-text">Um die Erfassung Ihrer Daten mittels des Facebook-Pixels auf unserer Webseite zu verhindern, klicken Sie bitte auf die Cookie-Einstellungen rechts unten und schalten den Bereich Marketing-Cookies aus.
  Wenn Sie die Cookies in diesem Browser löschen, dann müssen Sie den Link erneut klicken. Ferner gilt das Opt-Out nur innerhalb des von Ihnen verwendeten Browsers und nur innerhalb unserer Webdomain, auf der der Link geklickt wurde.</p>

  <p className="content-text"><small>Quelle: Datenschutz-Konfigurator von <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank" rel="noopener noreferrer">mein-datenschutzbeauftragter.de</a></small></p>
  </Layout>
)

export default PrivacyPage
